import './Orders.scss';

import { faShippingFast } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import _ from 'lodash';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTableRowClickEventParams } from 'primereact/datatable';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import ToastContext from '../../../context/ToastContext';
import { FileTypes } from '../../../enums/files';
import useAxios from '../../../hooks/useAxios';
import useMediaQuery from '../../../hooks/useMediaQuery';
import usePageTitleToggler from '../../../hooks/usePageTitleToggler';
import useResponsiveTableColumns from '../../../hooks/useResponsiveTableColumn';
import useRouteDialog from '../../../hooks/useRouteDialog';
import useTableState from '../../../hooks/useTableState';
import { WithSummary } from '../../../types/api';
import { OrderCollection } from '../../../types/api/orders';
import { ReduxState } from '../../../types/redux';
import { Unpacked } from '../../../types/util';
import {
  downloadFile,
  getFileName,
  printAddressOrSticker,
  printSpecification,
} from '../../../utils/helpers/files';
import { currencyFormat } from '../../../utils/helpers/formatting';
import { queryString } from '../../../utils/helpers/http';
import { httpQueryObject } from '../../../utils/helpers/misc';
import { tryString } from '../../../utils/helpers/parse';
import Filters from '../../Components/Filters/Filters';
import HeaderPages from '../../Components/HeaderPages/HeaderPages';
import Table from '../../DataTable/Table/Table';
import Flex from '../../Layout/flex/Flex';
import MainContent from '../../Layout/flex/MainContent';
import CancelOrderDialog from './Dialogs/Cancel/CancelOrderDialog';
import { ViewOrderDialog } from './Dialogs/View/Order/ViewOrderDialog';
import ViewStatusDialog from './Dialogs/View/Status/ViewStatusDialog';
import {
  additionalColumnProperties,
  generateGroupActions,
  getColumnHeadersMap,
  getMobileColumnHeadersMap,
  mobileAdditionalColumnProperties,
  tableStorageKey,
} from './Orders.functions';
import useTableFilters from './useTableFilters';

function Orders(): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const isOnBigScreen = useMediaQuery('(min-width: 1024px)');
  const isOnMobile = useMediaQuery('(max-width: 768px)');
  const [action, setAction] = useState<string>('');
  const { bottomRightToastRef } = useContext(ToastContext);
  const {
    tableRef,
    page,
    setPage,
    limit,
    setLimit,
    sortField,
    sortOrder,
    setSortField,
    setSortOrder,
    selectionMultiple,
    setSelectionMultiple,
  } = useTableState<Unpacked<OrderCollection>>(tableStorageKey);
  const loggedUser = useSelector<ReduxState, ReduxState['user']>(
    (state) => state?.user
  );

  const { headerFiltersCount, filters, resetAllFilters, httpFiltersObj } =
    useTableFilters(page, setPage!, limit);

  const [contextMenuSelection, setContextMenuSelection] = useState<
    Unpacked<OrderCollection> | undefined
  >(undefined);

  const [caller, setCaller] = useState<'group-actions' | 'context-menu'>(
    'context-menu'
  );

  const canLoadData = httpFiltersObj.date_from && httpFiltersObj.date_to;

  const { data, isLoading, reload, error } = useAxios<
    WithSummary<OrderCollection['data']>
  >(
    {
      url: '/orders' + queryString(httpQueryObject(httpFiltersObj)),
    },
    { skipWhen: !canLoadData }
  );

  const tableClassName = classNames({
    datatable:
      (!isOnMobile && !isOnBigScreen) ||
      (isOnBigScreen && !data?.pagination.total),
    'datatable-responsive': isOnMobile,
  });

  const columnHeadersMap = useMemo(() => getColumnHeadersMap(t), [t]);
  const columnMobileHeadersMap = useMemo(
    () => getMobileColumnHeadersMap(t),
    [t]
  );

  const { id: shipmentID, serialNo } =
    useParams<{ id?: string; serialNo?: string }>();

  const {
    show: showViewShipmentDialog,
    hide: hideViewShipmentDialog,
    isVisible: isViewShipmentDialogVisible,
  } = useRouteDialog(
    '/orders',
    `${shipmentID ?? contextMenuSelection?.id}/view`
  );

  const {
    show: showShipmentTrackingDialog,
    hide: hideShipmentTrackingDialog,
    isVisible: isShipmentTrackingDialogVisible,
  } = useRouteDialog(
    '/orders',
    `${serialNo ?? contextMenuSelection?.seriski_broj}/track`
  );

  const reloadPickupSpec = useCallback(
    (caller: string) => {
      const queryParams = {
        shipment_ids:
          caller === 'context-menu'
            ? contextMenuSelection
              ? [contextMenuSelection.id]
              : []
            : selectionMultiple?.map((s) => s?.id),
      };

      printSpecification(queryParams, bottomRightToastRef);
    },
    [bottomRightToastRef, contextMenuSelection, selectionMultiple]
  );

  const handlePrintSpecification = useCallback(
    (caller: string) => {
      reloadPickupSpec(caller);
    },
    [reloadPickupSpec]
  );

  const handleCMRecreateClick = useCallback(() => {
    if (!contextMenuSelection) {
      return;
    }
  }, [contextMenuSelection]);

  const {
    show: showCancelShipmentDialog,
    hide: hideCancelShipmentDialog,
    isVisible: isCancelShipmentDialogVisible,
  } = useRouteDialog('/orders', 'delete');

  usePageTitleToggler(
    !contextMenuSelection
      ? t('Loading...')
      : selectionMultiple?.length > 1
      ? t('Cancel multiple orders')
      : t('Cancel order {{serialNo}}', {
          serialNo: contextMenuSelection?.seriski_broj ?? '',
        }),
    t('Orders'),
    isCancelShipmentDialogVisible
  );

  const printAddressSticker = useCallback(
    async (caller: string, typePrint: 'AddressBook' | 'Sticker') => {
      const queryParams = {
        shipment_ids:
          caller === 'context-menu'
            ? contextMenuSelection
              ? [contextMenuSelection.id]
              : []
            : selectionMultiple?.map((s) => s?.id),
      };

      printAddressOrSticker(
        queryParams,
        caller === 'context-menu' ? contextMenuSelection : selectionMultiple,
        caller === 'group-actions',
        typePrint,
        loggedUser,
        bottomRightToastRef
      );
    },
    [bottomRightToastRef, contextMenuSelection, selectionMultiple, loggedUser]
  );

  const handleCMCancelClick = useCallback(() => {
    showCancelShipmentDialog();
  }, [showCancelShipmentDialog]);

  const { selectedColumns, setSelectedColumns, columnOptions, columns } =
    useResponsiveTableColumns(
      page,
      limit,
      columnHeadersMap,
      columnHeadersMap,
      columnMobileHeadersMap,
      (c: string) =>
        additionalColumnProperties(
          t,
          c as keyof typeof columnHeadersMap,
          setContextMenuSelection,
          handlePrintSpecification,
          handleCMRecreateClick,
          handleCMCancelClick
        ),
      (c: string) =>
        mobileAdditionalColumnProperties(
          c as keyof typeof columnMobileHeadersMap
        )
    );

  function handlePrintAddressGroup() {
    printAddressSticker('group-actions', 'AddressBook');
  }

  function handlePrintStickerGroup() {
    printAddressSticker('group-actions', 'Sticker');
  }

  function handleCreateOrderClick() {
    history.push('/orders/create');
  }

  const onCreateASimilarOrderBtnClick = useCallback(
    () =>
      history.push(
        !!contextMenuSelection
          ? `/orders/${contextMenuSelection?.id}/recreate`
          : '/orders/create'
      ),
    [contextMenuSelection, history]
  );

  const handleEditOrder = useCallback(
    () => history.push(`/orders/${contextMenuSelection?.id}/edit`),
    [contextMenuSelection, history]
  );

  function handleExportExcel() {
    if (!data?.data.length) {
      return;
    }

    downloadFile(
      `/orders/export/excel` +
        queryString(
          _.omit(httpFiltersObj, [
            'page',
            'limit',
            'fragile',
            'two_man_delivery',
            'adresnica_service_packaging_id',
          ]) as any
        ),
      getFileName(t('Orders'), undefined, true),
      FileTypes.XLSX,
      bottomRightToastRef
    );
  }

  useEffect(() => {
    if (action && contextMenuSelection) {
      setCaller('context-menu');

      if (action === 'view-details') {
        showViewShipmentDialog();
      }
      if (action === 'edit') {
        handleEditOrder();
      }
      if (action === 'similar-order') {
        onCreateASimilarOrderBtnClick();
      }
      if (action === 'track') {
        showShipmentTrackingDialog();
      }
      if (action === 'print-address') {
        printAddressSticker('context-menu', 'AddressBook');
      }
      if (action === 'print-sticker') {
        printAddressSticker('context-menu', 'Sticker');
      }
      if (action === 'print-specification') {
        handlePrintSpecification('context-menu');
      }
      if (action === 'cancel') {
        handleCMCancelClick();
      }
      setAction('');
      setContextMenuSelection(undefined);
      setSelectionMultiple([]);
    }
  }, [
    action,
    contextMenuSelection,
    handleCMCancelClick,
    printAddressSticker,
    selectionMultiple?.length,
    handleEditOrder,
    showShipmentTrackingDialog,
    showViewShipmentDialog,
    setSelectionMultiple,
    handlePrintSpecification,
  ]);

  const finalColumns = useMemo<JSX.Element[]>(
    () => [
      ...columns,
      <Column
        key="action-column"
        header={t('Actions')}
        field="actions"
        frozen
        alignFrozen="right"
        {...additionalColumnProperties(
          t,
          'actions',
          setContextMenuSelection,
          handleCMRecreateClick,
          handleCMCancelClick,
          setAction
        )}
      />,
    ],
    [
      columns,
      setContextMenuSelection,
      handleCMRecreateClick,
      handleCMCancelClick,
      setAction,
      t,
    ]
  );

  const paginatorLeft = (
    <span>
      {t('Total redemption')}:{' '}
      <b>
        {currencyFormat(data?.summary?.redemption ?? '', {
          showCurrency: true,
        })}
      </b>
    </span>
  );

  return (
    <div className="page shipments-page">
      <HeaderPages
        title={t('Orders')}
        subtitle={t('Here you can view and filter orders.')}
        icon={faShippingFast}
      >
        <Button
          type="button"
          label={t('Create Order')}
          icon="fas fa-plus"
          className="main-btn"
          onClick={() => handleCreateOrderClick()}
        />
      </HeaderPages>

      <ViewOrderDialog
        id={shipmentID ?? tryString(contextMenuSelection?.id)}
        visible={isViewShipmentDialogVisible}
        onHide={hideViewShipmentDialog}
      />

      <ViewStatusDialog
        serialNumber={serialNo ?? contextMenuSelection?.seriski_broj ?? ''}
        visible={isShipmentTrackingDialogVisible}
        onHide={hideShipmentTrackingDialog}
      />

      <CancelOrderDialog
        visible={isCancelShipmentDialogVisible}
        data={
          caller === 'context-menu'
            ? contextMenuSelection
              ? [contextMenuSelection]
              : []
            : selectionMultiple
        }
        onHide={hideCancelShipmentDialog}
        reloadOrders={reload}
        setSelectionMultiple={setSelectionMultiple}
      />

      <Flex direction="column">
        <Filters
          filters={filters}
          resetAllFilters={resetAllFilters}
          headerFiltersCount={headerFiltersCount}
        />
        <MainContent>
          <Table
            className={tableClassName}
            responsiveLayout="stack"
            breakpoint="768px"
            ref={tableRef}
            columns={finalColumns}
            data={data}
            isLoading={isLoading}
            reload={reload}
            isReloadDisabled={!canLoadData}
            hasError={!!error}
            headerTitle=""
            setPage={setPage}
            setLimit={setLimit}
            sortField={sortField}
            rows={limit}
            setSortField={setSortField}
            setSortOrder={setSortOrder}
            setSelection={setSelectionMultiple}
            sortOrder={sortOrder}
            selection={selectionMultiple}
            groupActionsModel={generateGroupActions(
              t,
              selectionMultiple,
              handlePrintAddressGroup,
              handlePrintSpecification,
              handlePrintStickerGroup,
              handleCMCancelClick,
              setCaller
            )}
            onRowDoubleClick={(e: DataTableRowClickEventParams) => {
              setContextMenuSelection(e.data);
              setAction('view-details');
            }}
            selectionMode="multiple"
            selectionPageOnly
            clearSelectionObj={httpFiltersObj}
            minGroupSelection={2}
            storageString={tableStorageKey}
            rebuildTooltip
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            columnOptions={columnOptions}
            paginatorLeft={paginatorLeft}
            exportToExcelButton
            onExportToExcelButtonClick={handleExportExcel}
            displayActionColumn
            contextMenuSelection={contextMenuSelection}
            setContextMenuSelection={setContextMenuSelection}
          />
        </MainContent>
      </Flex>
    </div>
  );
}
export default Orders;
