import { IntBool } from '../../enums/boolean';
import { WithPagination } from '../api';
import { Numeric } from '../general';
import { ReduxState } from '../redux';

// GET /orders
export type OrderCollection = WithPagination<
  {
    klient_od_id: number;
    id: number;
    seriski_broj: string;
    klient_od_ime: string;
    klient_do_ime: string;
    status_id: number;
  }[]
>;

// GET /orders/:id
export type OrderResource = {
  id: number;
  klient_naracatel_id: number;
  klient_od_ime: string;
  klient_od_id: number;
  klient_do_id: number;
  klient_do_ime: string;
  adresa_od: string | null;
  adresa_do: string | null;
  mobilen_do: string;
  telefon_do: string;
  mesto_do_ime: string;
  mesto_do_id: number;
  street_to_name: string;
  ulica_do_id: number;
  broj_do: string;
  vlez_do: string;
  stan_do: string;
  mobilen_od: string;
  telefon_od: string;
  mesto_od_id: number;
  mesto_od_ime: string;
  ulica_od_id: number;
  street_from_name: string;
  broj_od: string;
  vlez_od: string;
  stan_od: string;
  otkup: string;
  volumen: string;
  kolicina: string;
  sirina: string;
  visina: string;
  dolzina: string;
  cena: string | null;
  datum_kreiranje: string | null;
  datum_isporaka: string | null;
  datum_priem: string | null;
  dokument_broj: string | null;
  komentar: string | null;
  fragile?: IntBool;
  two_man_delivery?: IntBool;
  adresnica_service_packaging_id?: OrderPackaging;
  seriski_broj: string;
  barkod: string;
  status_id: number;
  status_name: string;
  tezina: string;
  payments: Payment[];
  proizvod_id: OrderType;
  proizvod_ime: string;
  order_id: number;
  vrednost: string;
  povraten_dokument: ReturnDocument;
  delivery_term_id: TimeFrame;
  priority: PriorityTimeFrame;
  hub_od_id: number;
  hub_do_id: number;
  hub_od_ime: string;
  hub_do_ime: string;
  return_shipment_id: number | null;
  origin_return_shipment_id: number | null;
  return_document_id: number | null;
  origin_return_document_id: number | null;
  delivery_location_type_id: ShippingLocation;
  pickup_location_type_id: ShippingLocation;
  reference1: string | null;
  reference2: string | null;
  reference_id: number | null;
  klient_otkup_id: number | null;
  specijaliziran_proizvod_id: string | null;
  personal_delivery: LetterType;
  replacement_shipment: number | null;
  ordererInvoice: number;
  senderInvoice: number;
  recipientInvoice: number;
};

// RequestPayload<
//  | POST /orders/add
//  | PUT /orders/:id
//  >
export type AddEditOrderRequestPayload = {
  // Sender
  klient_od_id: number;
  klient_od_ime: string;
  mobilen_od: string;
  telefon_od: string;
  mesto_od_id: Numeric;
  mesto_od_ime: string;
  ulica_od_id: Numeric;
  adresa_od: string;
  broj_od: string;
  vlez_od: string;
  stan_od: string;
  hub_od_id?: Numeric | null;
  pickup_location_type_id: ShippingLocation;

  // Recipient
  klient_do_id: number;
  klient_do_ime: string;
  mobilen_do: string;
  telefon_do: string;
  mesto_do_id: Numeric;
  mesto_do_ime: string;
  ulica_do_id: Numeric;
  adresa_do: string;
  broj_do: string;
  vlez_do: string;
  stan_do: string;
  hub_do_id?: Numeric | null;
  delivery_location_type_id: ShippingLocation;

  // Order settings
  proizvod_id: OrderType;
  kolicina: string;
  tezina: string;
  volumen: string;
  otkup?: string;
  vrednost?: string;
  reference1: string;
  reference2: string;
  reference_id: Numeric;
  povraten_dokument: any; // let it for now, in new form is fixed
  komentar: string;
  fragile: IntBool;
  two_man_delivery: IntBool;
  adresnica_service_packaging_id: OrderPackaging | undefined;
  klient_otkup_id: Numeric | null;
  specijaliziran_proizvod_id: string | null;
  personal_delivery?: LetterType;
  replacement_shipment?: any; // let it for now, in new form is fixed
  delivery_term_id?: any;
  priority?: any;

  // Payments
  payments?: Partial<Payment>[];
  ordererInvoice?: number;
  senderInvoice?: number;
  recipientInvoice?: number;

  // Misc
  klient_naracatel_id: ReduxState['user']['client_id'];
  calc_price: '0';
};

// RequestPayload<POST /orders/calculator>
export type CalculatorRequestPayload = {
  // Required
  product_id: number;
  pickup_city: string;
  delivery_city: string;

  // Optional
  sender_id?: string;
  recipient_id?: string;
  orderer_id?: string;
  pickup_address?: string;
  pickup_zip?: string;
  delivery_address?: string;
  delivery_zip?: string;
  pickup_hub_id?: Numeric;
  delivery_hub_id?: Numeric;
  secured_shipment?: string;
  return_document?: string;
  replacement_shipment?: string;
  weight?: string;
  cod_amount?: string;
  value_amount?: string;
  shipping_payer?: Payer;
  redemption_payer?: Payer;
  return_document_payer?: Payer;
  insurance_payer?: Payer;
  shipping_pay_cash?: PaymentMethod;
  redemption_pay_cash?: PaymentMethod;
  return_document_pay_cash?: PaymentMethod;
  insurance_pay_cash?: PaymentMethod;
  volume?: string;
  quantity?: string;
  personal_delivery?: LetterType;
  delivery_term_id?: string;
  priority?: string;
};

// POST /orders/calculator
export type CalculatorResponse = {
  customer_id: number;
  description: string;
  cash: PaymentMethod;
  quantity: number;
  unit_price: number;
  price: number;
  tariff_id: number;
  pricelist_id: number;
  service_id: number;
  service_name: string;
  vat_percent: number;
  created: string;
}[];

// ------------------------------------- //
// --------------- Other --------------- //
// ------------------------------------- //
export enum UnregisteredClientId {
  Sender = 1,
  Recipient = 2,
}

export enum PaymentMethod {
  Cash = 1,
  Invoice = 0,
}

export enum ServiceType {
  Postage = 1,
  Redemption = 2,
  ReturnDocument = 3,
  Insurance = 4,
}

export type Payment = {
  id: number | null;
  klient_id: number;
  usluga_id: ServiceType;
  opis: string;
  gotovina: PaymentMethod;
  iznos: number;
  usluga_ime: string;
  ddv: number;
  created: string;
  tarifa_id: number;
  cenovnik_id: number | null;
  kolicina: number;
  ediznos: number;
};

export enum OrderType {
  Package = 1,
  Letter = 2,
}

export enum ReturnDocument {
  None = 0,
  Include = 1,
}

export enum ReplacementShipment {
  None = 0,
  Include = 1,
}

export enum ShippingLocation {
  Hub = 1,
  HomeAddress = 2,
}

export enum Payer {
  Orderer = 0,
  Sender = 1,
  Recipient = 2,
}

export enum OrderPackaging {
  CardboardBox_300x200x200 = 1,
  CardboardBox_500x300x300,
  CardboardBox_500x300x500,
  SmallBag,
  BigBag,
}

export enum CodPaymentStatus {
  Unpaid = 0,
  Paid = 1,
}

export enum ReturnDocumentFilter {
  None = 0,
  Include = 1,
  ReturnDocument = 2,
}

export enum LetterType {
  Regular = 0,
  Recommended = 1,
  PersonalDelivery = 2,
}

export enum TimeFrame {
  None = 0,
  SameWorkDay = 1,
  NextWorkDay = 2,
  ThreeWorkDays = 3,
}

export enum PriorityTimeFrame {
  None = 0,
  Priority = 1,
}
