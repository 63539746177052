import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import { useMemo } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import FieldWithErrors from '../../../../../../Forms/ReactHookForm/FieldWithErrors/FieldWithErrors';
import {
  FormFields,
  getTimeFrameOptions,
} from '../../../CreateEditRecreate.functions';

function TimeFrame(): JSX.Element {
  const { t } = useTranslation();
  const delivery_term_id = useWatch<FormFields, 'delivery_term_id'>({
    name: 'delivery_term_id',
  });

  const timeFrameOptions = useMemo(
    () => getTimeFrameOptions(t).sort((a, b) => a.value - b.value),
    [t]
  );

  return (
    <>
      <FieldWithErrors name="delivery_term_id" label={t('Time frame')}>
        <Controller
          name="delivery_term_id"
          render={({ field }) => (
            <Dropdown
              {...field}
              value={field.value}
              name="delivery_term_id"
              id={field.name}
              options={timeFrameOptions}
              onChange={(e) => field.onChange(e.value)}
            />
          )}
        />
      </FieldWithErrors>
      {(delivery_term_id === 2 || delivery_term_id === 3) && (
        <FieldWithErrors name="priority" label={t('Priority delivery')}>
          <Controller
            name="priority"
            render={({ field }) => (
              <InputSwitch
                {...field}
                checked={Boolean(field.value)}
                inputId={field.name}
              />
            )}
          />
        </FieldWithErrors>
      )}
    </>
  );
}

export default TimeFrame;
