import './Welcome.scss';

import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import welcomeIllustrationBlue from '../../../assets/svg/welcome-blue.svg';
import welcomeIllustrationGreen from '../../../assets/svg/welcome-green.svg';
import welcomeIllustrationOrange from '../../../assets/svg/welcome-orange.svg';
import welcomeIllustrationPurple from '../../../assets/svg/welcome-purple.svg';
import welcomeIllustrationRed from '../../../assets/svg/welcome-red.svg';
import usePageTitle from '../../../hooks/usePageTitle';
import { ReduxState } from '../../../types/redux';
import { getOldWebUrl } from '../../../utils/helpers/misc';

const illustration: Record<string, string> = {
  red: welcomeIllustrationRed,
  blue: welcomeIllustrationBlue,
  green: welcomeIllustrationGreen,
  purple: welcomeIllustrationPurple,
  orange: welcomeIllustrationOrange,
};

function Welcome() {
  const { t } = useTranslation();

  usePageTitle(t('Welcome'));

  const user = useSelector<ReduxState, ReduxState['user']>(
    (state) => state.user,
    shallowEqual
  );

  return (
    <div className="page welcome-page">
      <div className="welcome-grid">
        <div>
          <img
            src={illustration[process.env.REACT_APP_THEME ?? 'blue']}
            alt=""
            className="unselectable"
          />
        </div>

        <div>
          <h2>
            {t('Welcome back, {{user}}!', {
              user: user.client_name,
            })}
          </h2>

          <p>
            {t(
              "The Accura system developed by Koder is a system which supports postal offices' working activities in every segment. It consists of mobile applications intended for use by couriers and warehousemen, as well as web applications used for managing the post office's resources, including order creation and tracking in an autonomous manner."
            )}
          </p>

          {/* <p>
            {t('For more information about our company, visit our')}{' '}
            <a href="https://www.koder.mk/" target="_blank" rel="noreferrer">
              {t('web site')}
            </a>
            .
          </p> */}

          <p>
            <i>
              {t('To use the old customer website, please click')}{' '}
              <a href={getOldWebUrl()}>{t('here')}</a>.
            </i>
          </p>

          <h4 className="p-text-secondary p-mt-5 p-mb-2">{t('Quick links')}</h4>

          <div>
            <Link to="/orders/create">
              <Button
                type="button"
                label={t('Create Order')}
                className="p-mb-2 p-mr-2"
              />
            </Link>

            <Link to="/orders">
              <Button
                type="button"
                label={t('Orders')}
                className="p-mb-2 p-mr-2"
              />
            </Link>

            <Link to="/orders/batch">
              <Button
                type="button"
                label={t('Batch Orders')}
                className="p-mb-2 p-mr-2"
              />
            </Link>

            <Link to="/warrants">
              <Button
                type="button"
                label={t('Warrants')}
                className="p-mb-2 p-mr-2"
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Welcome;
