import './ViewOrderDialog.scss';

import classNames from 'classnames';
import dayjs from 'dayjs';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import useAxios from '../../../../../../hooks/useAxios';
import useMediaQuery from '../../../../../../hooks/useMediaQuery';
import {
  OrderResource,
  PaymentMethod,
} from '../../../../../../types/api/orders';
import { formatPhoneOrMobileNumber } from '../../../../../../utils/helpers/phoneNumbers';

type Props = {
  id: string | undefined;
  visible: boolean;
  onHide: () => void;
};

export function ViewOrderDialog({ id, visible, onHide }: Props): JSX.Element {
  const history = useHistory();
  const { t } = useTranslation();
  const isOnMobile = useMediaQuery('(max-width: 600px)');
  const { data } = useAxios<OrderResource>(
    {
      url: `/orders/${id}`,
    },
    { skipWhen: !id || !visible }
  );

  const senderAddress = `${data?.adresa_od ?? ''}, ${data?.mesto_od_ime ?? ''}`;
  const recipientAddress = `${data?.adresa_do ?? ''}, ${
    data?.mesto_do_ime ?? ''
  }`;
  const titleValuePairClassName = classNames('p-d-flex', {
    'p-flex-row  p-mb-2': !isOnMobile,
    'p-flex-column  p-mb-3': isOnMobile,
  });

  const paymentTableData = useMemo<JSX.Element[]>(
    () =>
      data?.payments?.map((p) => (
        <tr key={p.id}>
          <td className="service-type">{p.usluga_ime}</td>
          <td>{p.iznos}</td>
          <td>
            {p.klient_id === data?.klient_od_id
              ? t('Sender')
              : p.klient_id === data?.klient_do_id
              ? t('Recipient')
              : t('Orderer')}
          </td>
          <td>
            {p.gotovina === PaymentMethod.Cash ? t('Cash') : t('Invoice')}
          </td>
        </tr>
      )) ?? [],
    [data?.klient_do_id, data?.klient_od_id, data?.payments, t]
  );

  const dialogFooter = (
    <div className="p-pt-4">
      <Button
        label={t('Edit')}
        className="p-button-secondary p-button-text"
        disabled={id === undefined || Number(data?.status_id) >= 35}
        onClick={() => history.push(`/orders/${data?.id}/edit`)}
      />
      <Button label={t('Close')} onClick={onHide} />
    </div>
  );

  return (
    <Dialog
      header={t('Order {{orderSerialNo}}', {
        orderSerialNo: data?.seriski_broj ?? '',
      })}
      footer={dialogFooter}
      visible={visible}
      onHide={onHide}
      maximizable
      maximized={useMediaQuery('(max-width: 768px)')}
      className="view-order-dialog"
    >
      <div className="view-order-page">
        <div className="info-card">
          <div className="p-d-flex p-jc-start p-ai-center">
            <i className="fas fa-truck-loading title-icon"></i>
            <h3>{t('General Information')}</h3>
          </div>
          <div className="info-card-general">
            <div>
              <div className={titleValuePairClassName}>
                <span className="info-header">{t('Serial Number')}</span>
                <span>{data?.seriski_broj}</span>
              </div>
              <div className={titleValuePairClassName}>
                <span className="info-header">{t('Order Type')}</span>
                <span>{data?.proizvod_ime ?? '-'}</span>
              </div>
              <div className={titleValuePairClassName}>
                <span className="info-header">{t('Status')}</span>
                <span>{data?.status_name ?? '-'}</span>
              </div>
              <div className={titleValuePairClassName}>
                <span className="info-header">{t('Created Date')}</span>
                <span>
                  {data?.datum_kreiranje
                    ? dayjs(data.datum_kreiranje).format(
                        'DD MMM YYYY, HH:mm:ss'
                      )
                    : '-'}
                </span>
              </div>
              <div className={titleValuePairClassName}>
                <span className="info-header">{t('Reception Date')}</span>
                <span>
                  {data?.datum_priem
                    ? dayjs(data.datum_priem).format('DD MMM YYYY, HH:mm:ss')
                    : '-'}
                </span>
              </div>
              <div className={titleValuePairClassName}>
                <span className="info-header">{t('Delivery Date')}</span>
                <span>
                  {data?.datum_isporaka
                    ? dayjs(data?.datum_isporaka).format(
                        'DD MMM YYYY, HH:mm:ss'
                      )
                    : '-'}
                </span>
              </div>
            </div>
            <div>
              <div className={titleValuePairClassName}>
                <span className="info-header">{t('Weight (kg)')}</span>
                <span>{data?.tezina ?? '-'}</span>
              </div>
              <div className={titleValuePairClassName}>
                <span className="info-header">{t('Fragile')}</span>
                <span>{data?.fragile === 0 ? t('No') : t('Yes')}</span>
              </div>
              <div className={titleValuePairClassName}>
                <span className="info-header">{t('Two man delivery')}</span>
                <span>{data?.two_man_delivery === 0 ? t('No') : t('Yes')}</span>
              </div>
              <div className={titleValuePairClassName}>
                <span className="info-header">
                  {t('Pickup from warehouse')}
                </span>
                <span>{data?.hub_od_ime ?? '-'}</span>
              </div>
              <div className={titleValuePairClassName}>
                <span className="info-header">{t('Тake to warehouse')}</span>
                <span>{data?.hub_do_ime ?? '-'}</span>
              </div>
              <div className={titleValuePairClassName}>
                <span className="info-header">{t('Note')}</span>
                <span>
                  {data?.komentar || (
                    <i>{t('There are no notes for this order')}</i>
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="info-card-customers">
          <div className="info-card-customer">
            <div className="p-d-flex p-jc-start p-ai-center">
              <i className="fas fa-map-marker-alt title-icon"></i>
              <h3>{t('Sender Information')}</h3>
            </div>
            <div className={titleValuePairClassName}>
              <span className="info-header">{t('Name')}</span>
              <span>{data?.klient_od_ime}</span>
            </div>
            <div className={titleValuePairClassName}>
              <span className="info-header">{t('Address')}</span>
              <span>{senderAddress}</span>
            </div>
            <div className={titleValuePairClassName}>
              <span className="info-header">{t('Phone')}</span>
              <span>
                {data?.telefon_od
                  ? formatPhoneOrMobileNumber(data.telefon_od)
                  : '-'}
              </span>
            </div>
            <div className={titleValuePairClassName}>
              <span className="info-header">{t('Mobile')}</span>
              <span>
                {data?.mobilen_od
                  ? formatPhoneOrMobileNumber(data.mobilen_od)
                  : '-'}
              </span>
            </div>
            <div className={titleValuePairClassName}>
              <span className="info-header">{t('Hub')}</span>
              <span>
                {data?.mobilen_od
                  ? formatPhoneOrMobileNumber(data.mobilen_od)
                  : '-'}
              </span>
            </div>
          </div>
          <div className="info-card-customer">
            <div className="p-d-flex p-jc-start p-ai-center">
              <i className="fas fa-flag title-icon"></i>
              <h3>{t('Recipient Information')}</h3>
            </div>
            <div className={titleValuePairClassName}>
              <span className="info-header">{t('Name')}</span>
              <span>{data?.klient_do_ime}</span>
            </div>
            <div className={titleValuePairClassName}>
              <span className="info-header">{t('Address')}</span>
              <span>{recipientAddress}</span>
            </div>
            <div className={titleValuePairClassName}>
              <span className="info-header">{t('Phone')}</span>
              <span>
                {data?.telefon_do
                  ? formatPhoneOrMobileNumber(data.telefon_do)
                  : '-'}
              </span>
            </div>
            <div className={titleValuePairClassName}>
              <span className="info-header">{t('Mobile')}</span>
              <span>
                {data?.mobilen_do
                  ? formatPhoneOrMobileNumber(data.mobilen_do)
                  : '-'}
              </span>
            </div>
          </div>
        </div>

        <div className="p-fluid info-card">
          <div className="p-d-flex p-jc-start p-ai-center">
            <i className="fas fa-hand-holding-usd title-icon"></i>
            <h3>{t('Payments and Additional Services')}</h3>
          </div>
          <div>
            <div className="table-wrapper">
              <table className="payments-table">
                <thead>
                  <tr>
                    <th></th>
                    <th>{t('Price')}</th>
                    <th>{t('Pays')}</th>
                    <th>{t('Type')}</th>
                  </tr>
                </thead>
                <tbody className="p-fluid">
                  {paymentTableData}
                  <tr>
                    <td className="service-type">{t('Total')}</td>
                    <td>{data?.cena}</td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
